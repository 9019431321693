









































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Tabs from "@/components/mobile/home/Tabs.vue";

@Component({
  components: {
    Layout,
    Tabs,
  },
})
export default class extends Vue {
  hbgsrc: string = require("@/assets/home/img/shehuizerenbg.png");
  tabs = [
    {
      id: 1,
      title: "社会责任",
    },
    {
      id: 2,
      title: "零碳与未来",
    },
    {
      id: 3,
      title: "人才与发展",
    },
  ];
  list = [
    {
      id: 1,
      ref: "one",
      title: "环境",
      des: "持续推进低碳绿色建筑的发展，不断打造绿色办公新模式，领跑城市楼宇绿色新赛道。以绿色环保的先锋态度，以智慧和科技为两翼，积极推动环境可持续发展。",
      src: require("@/assets/social/img/huanjing.png"),
      url: "/home/social/enviroment",
    },
    {
      id: 2,
      ref: "two",
      title: "社会",
      des: "建设扶贫光伏电站，加快农村能源转型发展，激发贫困地区发展动力及发展能力，为乡村振兴注入绿色活力，创造社会公益价值。",
      src: require("@/assets/social/img/shehui.png"),
      url: "/home/social/society",
    },
    {
      id: 3,
      ref: "three",
      title: "责任",
      des: "推进低碳社会经济与构建智慧能源生态，用以科技+服务助力环境可持续发展，将社会责任一以贯之。",
      src: require("@/assets/social/img/zhili.png"),
      url: "",
    },
  ];
  mlist = [
    {
      id: 1,
      title: "环境",
      src: require("@/assets/social/img/huanjing.png"),
      subtitle:
        "持续推进低碳绿色建筑高效发展，不断打造绿色办公新模式，领跑城市楼宇绿色新赛道。秉承绿色环保的先锋态度，以智慧和科技为两翼，积极推动环境可持续发展。",
    },
    {
      id: 2,
      title: "社会",
      src: require("@/assets/social/img/shehui.png"),
      subtitle:
        "积极建设扶贫光伏电站，加快农村能源转型发展，挖掘贫困地区发展潜力及发展能力，为乡村振兴注入绿色活力，持续创造社会公益价值。",
    },
    {
      id: 3,
      title: "治理",
      src: require("@/assets/social/img/zhili.png"),
      subtitle:
        "誉照科技通过指定短期、中期、长期的发展目标，有计划、分阶段地实现净零碳。利用自身的可再生资源解决方案、碳管理与智慧能源管理系统，不断提高能管效率，增加绿电消费所占比例。",
    },
  ];

  navigate(path: string) {
    this.$router.push({
      path,
    });
  }
}
